import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'

function Contact() {
    document.title = "Help | Ekna2k23"
  return (
    <div><Header/>
    <div className='pt-16 bg-slate-800'><Footer/></div>
    
    </div>

  )
}

export default Contact